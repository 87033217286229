import fetch from '../module/fetch'
import signature from '../module/signature'
import { apiUrl, retouchApi, domain, cloudStorageAPI, retouchBaseUrl, albumRevisionUrl, baseUrl, gsUrl, micrositeServerUrl } from '../config/env'
let docWidth = document.documentElement.clientWidth;

/********************************************************
 *                                                      *
 *　                       页面数据                   　  *
 *                                                      *
 ********************************************************/
// 判断用户能否参与3.3折活动
export const canJoinActive = () => fetch(signature(apiUrl + `/rest/v3/user/old/send/album`, {}, {}));

//获取用户拥有的相册集
export const getPhotoSet = () => fetch(signature(apiUrl + `/rest/v3/get/user/set`, {}, {}));

// 相册集跳转开关
export const settingPhotoSetAPI = (setIdN, jumpState, jumpUrl) => fetch(signature(albumRevisionUrl + '/rest/v3/album/set/jumpStateAndJumpUrl', {}, { setIdN, jumpState, jumpUrl }), { setIdN, jumpState, jumpUrl }, 'post');
//查询相册列表
export const getAlbumListData = (page, cnt, type, setId, title, startTime, endTime, sub) => fetch(signature(apiUrl + `/rest/v3/album/query/page${page}/cnt${cnt}/type${type}/setId${setId}/title${title}/startTime${startTime}/endTime${endTime}/sub${sub}`, {}, { page, cnt, type, setId, title, startTime, endTime, sub }));

//x新建相册  相册集中 相册的增减
export const editPhotoSet = (albumSetId, albumIds, type) => fetch(signature(apiUrl + '/rest/v3/edit/set/album', {}, { albumSetId, albumIds, type }), { albumSetId, albumIds, type }, 'post');

//个人中心回收站一键删除
export const allDeleteRecycle = (password) => fetch(signature(apiUrl + '/rest/v3/album/cleanRecycle', {}, { password }), { password }, 'post');

//获取m端token
export const getMobileToken = () => fetch(signature(apiUrl + `/rest/v3/get/mtoken`, {}, {}));

// 获取大活动状态
export const getActivityStatus = () => fetch(signature(apiUrl + `/rest/v3/index/big/activity/status`, {}, {}));

// 使用邀请码邀请用户成为修图师审核员摄影师
export const inviteCodeCharactar = (inviteCode, type) => fetch(signature(apiUrl + '/rest/v3/app/invite/auth', {}, { inviteCode, type }), { inviteCode, type }, 'post');

// 用户点击领取一个ai修图权限
// export const getAiAuth = () => fetch(signature(apiUrl + '/rest/v3/ai/send/auth', {}, {}), {}, 'post');

/////////帮助中心开始//////////
//获取一二级目录
// export const getSupportDirectory = () => fetch(signature(apiUrl + `/rest/v3/help/get/directory`, {}, {}));

//获取热门搜索
// export const getHotKey = () => fetch(signature(apiUrl + `/rest/v3/help/get/hot`, {}, {}));

//获取二级目录下问题 分页
// export const getSupportList = (pageNum, pageSize, catalogueId, orderType) => fetch(signature(apiUrl + '/rest/v3/help/get/list', {}, { pageNum, pageSize, catalogueId, orderType }), { pageNum, pageSize, catalogueId, orderType }, 'post');

//关键词搜索问题 分页
// export const getSupportKeyword = (pageNum, pageSize, keyword, orderType) => fetch(signature(apiUrl + '/rest/v3/help/search/list', {}, { pageNum, pageSize, keyword, orderType }), { pageNum, pageSize, keyword, orderType }, 'post');

//增加浏览量,返回可能要了解的问题
// export const getSupportMayknow = (helpId) => fetch(signature(apiUrl + '/rest/v3/help/increase/view', {}, { helpId }), { helpId }, 'post');

//用户反馈是否有帮助
// export const supportFeedback = (helpId, feedType) => fetch(signature(apiUrl + '/rest/v3/help/feed/back', {}, { helpId, feedType }), { helpId, feedType }, 'post');

//获取客服联系方式
// export const getServiceInfo = () => fetch(signature(apiUrl + `/rest/v3/help/get/contact`, {}, {}));
//////帮助中心结束/////////

//相册标题栏配置单独的接口
// export const setTopTitle = (key, value, albumId) => fetch(signature(apiUrl + '/rest/v3/album/create/more', {}, { key, value, albumId }), { key, value, albumId }, 'post');

//保存相册付费设置
// export const setPayPhoto = (albumIdN, isFree, price, payAlbumType, info, incomeOwner, incomeGrapher) => fetch(signature(apiUrl + '/rest/v3/pc/album/pay/setting', {}, { albumIdN, isFree, price, payAlbumType, info, incomeOwner, incomeGrapher }), { albumIdN, isFree, price, payAlbumType, info, incomeOwner, incomeGrapher }, 'post');


//获取查询相册流量
export const getVideoPackageStream = (albumIdN) => fetch(signature(apiUrl + '/rest/v3/album/flow', {}, { albumIdN }), { albumIdN }, 'post');

//修图页面 获取是否有标星权限
export const couldStar = (albumIdN) => fetch(signature(retouchApi + '/rest/v3/user/check/auth', {}, { albumIdN }), { albumIdN }, 'post')

//图片打星分数
export const starCount = (albumIdN, photoId, starLevel) => fetch(signature(apiUrl + '/rest/v3/retouch/star/photo', {}, { albumIdN, photoId, starLevel }), { albumIdN, photoId, starLevel }, 'post');

//获取我的星标大片
export const getMyStarImg = (labelId, days, starLevel, pageNum, pageSize) => fetch(signature(apiUrl + '/rest/v3/retouch/my/star', {}, { labelId, days, starLevel, pageNum, pageSize }), { labelId, days, starLevel, pageNum, pageSize }, 'post')
    // export const getMyStarImg = (labelId,days,starLevel) => fetch(signature(apiUrl + '/rest/v3/retouch/my/star', {}, { labelId, days, starLevel }), { labelId, days, starLevel }, 'post')

//我的星标大片统计接口
export const getImgCount = () => fetch(signature(apiUrl + `/rest/v3/retouch/my/star/statistics`, {}, {}));
//问题反馈
export const getContact = (phone, content, type, backFrom=100010) => {
    let encodecontent = encodeURIComponent(content);
    return fetch(signature(apiUrl + `/rest/v3/flashpass/pc/coopsignup`, {}, { phone, content, type, backFrom }), { phone, content: encodecontent, type , backFrom}, 'post')
}

//获取视频直播信息
// export const getLiveInfo = (type, albumIdN) => fetch(signature(apiUrl + `/rest/v3/app/redirect/video/show/type${type}/albumIdN${albumIdN}`, {}, { type, albumIdN }));

//生成直播视频推流链接
export const createVideoStream = (albumIdN, id, type) => fetch(signature(apiUrl + '/rest/v3/album/banner/redirect/video/link/create', {}, { albumIdN, id, type }), { albumIdN, id, type }, 'post');

//生成直播视频场次或修改直播类型
// export const getLiveId = (albumIdN, title, startDate, id) => fetch(signature(apiUrl + '/rest/v3/album/banner/redirect/video/create', {}, { albumIdN, title, startDate, id }), { albumIdN, title: encodeURIComponent(title), startDate, id }, 'post')

//视频直播结束接口
export const endLive = (albumIdN, id) => fetch(signature(apiUrl + '/rest/v3/album/banner/redirect/video/stop', {}, { albumIdN, id }), { albumIdN, id }, 'post')

//删除视频场次
export const deleteLive = (albumIdN, id, pwd) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/banner/redirect/video/delete`, {}, { albumIdN, id, pwd }), { albumIdN, id, pwd }, 'post')

// 发起复制专题
export const copySpecial = (topicIdN) => fetch(signature(micrositeServerUrl + `/rest/v3/speical/topic/copySpecialTopic`, {}, { topicIdN }), { topicIdN }, 'post')

// 查询专题的复制状态
export const selectCopySpecial = (topicIdN) => fetch(signature(micrositeServerUrl + `/rest/v3/speical/topic/queryCopyState`, {}, { topicIdN }), { topicIdN }, 'post')

//视频直播套餐下单
// export const buyLivePackage = (quantity, goodsId, albumIdN) => fetch(signature(apiUrl + '/rest/v3/goods/order', {}, { quantity, goodsId, albumIdN }), { quantity, goodsId, albumIdN }, 'post')

//查询流量套餐
export const getGprsPackage = (type) => fetch(signature(apiUrl + `/rest/v3/goods/type${type}`, {}, { type }));

//获取案例页标签统计数据
export const getCasesTags = () => fetch(signature(apiUrl + '/rest/v3/label/album/case'))

//获取大客户简介
export const getCustomerInfo = (sub) => fetch(signature(apiUrl + `/rest/v3/user/big/custom/sub${sub}`, {}, { sub }));

//获取所有标签
export const getAllTags = () => fetch(signature(apiUrl + '/rest/v3/label/all'));

//获取购买视频套餐二维码(此处的id是写死的)
export const getVideoPackage = (quantity, goodsId, albumIdN) => fetch(signature(apiUrl + `/rest/v3/goods/order`, {}, { quantity, goodsId, albumIdN }), { quantity, goodsId, albumIdN }, 'post');

//确认订单支付完成且回调处理完毕
export const getPayResult = (o) => fetch(signature(apiUrl + `/rest/v3/order/ck/o${o}`, {}, { o }));

//获取云存储信息
// export const getAlbumStorageInfo = () => fetch(signature(apiUrl + '/rest/v3/cloud/buy'));

//购买云存储
// export const buyAlbumStorage = (buyType, month, size) => fetch(signature(apiUrl + '/rest/v3/cloud/pay', {}, { buyType, month, size }), { buyType, month, size }, 'post');

//摄影师简历分享
export const shareResume = (worksIds) => fetch(signature(apiUrl + '/rest/v3/pg/share/resume', {}, { worksIds }), { worksIds }, 'post');

//pc主页获取对应页面的相册

export const getAlbumList = (cnt, pag, sub, type, startTime, endTime) => fetch(signature(apiUrl + `/rest/v3/album/pc/all/pag${pag}/cnt${cnt}/sub${sub}/type${type}/startTime${startTime}/endTime${endTime}`, {}, { cnt, pag, sub, type ,startTime, endTime}));

//pc 设置页获取常用菜单
// export const getMenuFrequently = () => fetch(signature(apiUrl + `/rest/v3/album/pc/get/menuFrequently`));

//pc 设置页设置常用菜单
// export const setMenuFrequently = (type, state) => fetch(signature(apiUrl + '/rest/v3/album/pc/set/menuFrequently', {}, { type, state }), { type, state }, 'post');

// pc 主页获取图片分类 及 合作伙伴数据
// export const getImgData = () => fetch(signature(apiUrl + `/rest/v3/index/photos`, {}, {}));

// pc 主页获取新闻
export const getIndexNews = () => fetch(signature(apiUrl + `/rest/v3/index/acquire/news`, {}, {}));

// pc 首页提交合作申请
export const postCooperation = ({ contact="", phone="", actId = "", content="", code = "", address="", backFrom }) => fetch(signature(apiUrl + '/rest/v3/flashpass/nomsg/coopapply/change', {}, { contact, phone, actId, content, code, address, backFrom }), { contact, phone, actId, content, code, address, backFrom }, 'post');

// pc 短信验证接口 使用ip限流
export const postSmsVer = ({ phone, vcodeType = 13, globalCode = "" }) => fetch(signature(apiUrl + '/rest/v3/pc/ip/limit/send/sms', {}, { phone, vcodeType, globalCode }), { phone, vcodeType, globalCode }, 'post');

//pc获取相册密码和二维码地址
export const getAlbumShare = (albumId) => fetch(signature(apiUrl + `/rest/v3/pc/pwd/code/albumId${albumId}`, {}, { albumId }));

//pc删除相册
export const delAlbum = (albumId) => fetch(signature(apiUrl + '/rest/v3/album/delete', {}, { albumId }), { albumId }, 'post');
//PC删除相册需要密码
export const delAlbumPassword = (albumIdN, password) => fetch(signature(apiUrl + '/rest/v3/album/with/password/delete', {}, { albumIdN, password }), { albumIdN, password }, 'post');

//pc获取对应域名的页面信息
export const getUserPageInfo = (sub) => fetch(signature(apiUrl + `/rest/v3/album/pc/detail/sub${sub}`, {}, { sub }));


//PC 查看用户版本次数
export const getFlashPassState = (sub) => fetch(signature(apiUrl + `/rest/v3/flashpass/states`, {}, {}));

//PC创建直播相册
// export const createliveAlbum = (title, albumCode, albumAddr, adate, ctm, style) => fetch(signature(apiUrl + `/rest/v3/album/create/pc/sim`, {}, { title, albumCode, albumAddr, adate, ctm, style }), { title, albumCode, albumAddr, adate, ctm, style }, 'post');
//PC创建直播相册New
// export const createAlbumNew = (ctm) => fetch(signature(apiUrl + '/rest/v3/album/create/pc/change', {}, { ctm }), { ctm }, 'post');

//pc 相册改版 创建相册 不需要选择版本直接跳转至基本设置
export const createRevisionAlbum = (title, setId) => fetch(signature(albumRevisionUrl + '/rest/v3/album/base/create', {}, {title, setId}), {title, setId}, 'post');
//pc 相册改版  获取基本设置信息
export const getAlbumRevisionInfo = (albumIdN) => fetch(signature(albumRevisionUrl + '/rest/v3/album/query/baseSet', {}, {albumIdN}), {albumIdN}, 'post');


//pc 相册改版  开关设置
// 顶部宣传   IS_VIDEO_INTERACTION(1, "视频直播类型","is_video_interaction"),
// 营销获客   PRO_MARKETING(2, "个人宣传","is_personal_market"),
// 相册菜单   HOST_SORT(3,"热门排序","host_sort"),
//           CMT_AUDITING(4, "评论审核", "cmt_auditing"),
//           IS_BARRAGE(5,"弹幕开关","is_barrage"),
// 侧边栏     IS_TOOLS_SPREAD(6,"侧边栏菜单默认展开","is_tools_spread"),
//           IS_BATCH_DOWNLOAD(7,"批量下载","is_batch_download"),
//           IMG_IDENTIFY(8,"人脸识别","img_identify"),
// 大图信息   IS_LIKE(9,"照片点赞","img_identify"),
//           IS_IMAGE_SHARE(10,"大图分享","is_image_share"),
//           IS_IMAGE_PHOTO_BAR(11,"图片信息","is_image_photo_bar"),
//           PG_INFO(12,"摄影师修图师","is_img_pg_ bar, is_planner"),
export const saveBaseSwitchSet = (albumIdN, typeState) => fetch(signature(albumRevisionUrl + '/rest/v3/album/set/baseSwitchSet', {}, {albumIdN, typeState}), {albumIdN, typeState}, 'post');
// pc 相册改版  基本设置保存设置
export const saveBasicAlbumSet = (albumIdN, title, albumCode, albumAddr, adate, addrDetail, style, uuid, GCJLocation, WGSLocation, isDefault, dsc, startDate, endDate, englishTitle, showNavigation, isSyncTitle) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/baseSet`, {}, { albumIdN, title, albumCode, albumAddr, adate, addrDetail, style, uuid, GCJLocation, WGSLocation, isDefault, dsc, startDate, endDate, englishTitle, showNavigation }), { albumIdN, title, albumCode, albumAddr, adate, addrDetail, style, uuid, GCJLocation, WGSLocation, isDefault, dsc, startDate, endDate, englishTitle, showNavigation, isSyncTitle }, 'post')




// pc 相册改版  顶部广告保存设置
export const saveTopBannerSet = (albumIdN, bannerTitle) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/bannerTitleSet`, {}, { albumIdN, bannerTitle }), { albumIdN, bannerTitle }, 'post')
// pc 相册改版 顶部宣传信息基本设置信息回显
export const getTopBannerInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryBannerBaseInfo`, {}, { albumIdN }), { albumIdN }, 'post')





// pc 相册改版 点赞气泡  保存设置
export const saveBubbleSet = (albumIdN, isGoodOpen, goodBubbleType, json) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/goodBubbleSet`, {}, { albumIdN, isGoodOpen, goodBubbleType, json }), { albumIdN, isGoodOpen, goodBubbleType, json }, 'post')
// pc 相册改版 相册查询
export const queryAlbums = (page, cnt, type, setId, title, startTime, endTime, sub, sort, labelIds, isTransfer = 0) => fetch(signature(gsUrl + `/rest/v3/album/query/queryAlbumList`, {}, { page, cnt, type, setId, title, startTime, endTime, sub }), { page, cnt, type, setId, title, startTime, endTime, sub, sort, labelIds,isTransfer }, 'post')

// pc 相册改版 相册分享回显
export const getAlbumShareInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/albumShare`, {}, { albumIdN }), { albumIdN }, 'post')

// pc 相册改版 相册设置-自定义相册风格
export const newCreateAlbumCustom = (albumIdN, title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, id, backgroundColor) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/customizeStyleSet`, {}, { albumIdN, title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, id }), { albumIdN, title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, id, backgroundColor }, 'post')
// pc 相册改版 相册设置-删除自定义相册风格
export const newDeleteAlbumCustom = (albumIdN, customStyleId) => fetch(signature(albumRevisionUrl + `/rest/v3/album/set/delCustomizeStyle`, {}, { albumIdN, customStyleId }), { albumIdN, customStyleId }, 'post')
// pc 相册改版 微信分享-回显数据
export const getWechatShareInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryWeChatShare`, {}, { albumIdN }), { albumIdN }, 'post')
// pc 相册改版 自定义广告-回显数据
export const getCustomAdInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryCustomAds`, {}, { albumIdN }), { albumIdN }, 'post')
// pc 相册改版 浏览设置-回显数据
export const getDefaultSetInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryAlbumBrowseSet`, {}, { albumIdN }), { albumIdN }, 'post')
// pc 相册改版 大图信息--回显数据
export const getBigPictureSetInfo = (albumIdN) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryAlbumBigPhoto`, {}, { albumIdN }), { albumIdN }, 'post')
// export const getAlbumListData = (page, cnt, type, setId, title, startTime, endTime, sub) => fetch(signature(apiUrl + `/rest/v3/album/query/page${page}/cnt${cnt}/type${type}/setId${setId}/title${title}/startTime${startTime}/endTime${endTime}/sub${sub}`, {}, { page, cnt, type, setId, title, startTime, endTime, sub }));



//PC 设置头像图片确认接口
export const confirmUploadFaceImgOld = (x, y, width, height) => fetch(signature(apiUrl + `/rest/v3/comfirm/face`, {}, { x, y, width, height }), { x, y, width, height }, 'post');
export const confirmUploadFaceImg = (x, y, width, height, type) => fetch(signature(apiUrl + `/rest/v3/comfirm/face`, {}, { x, y, width, height, type }), { x, y, width, height, type }, 'post');
export const confirmUploadCoverImg = ({ x, y, width, height, type, path, bucket }) => fetch(signature(apiUrl + `/rest/v3/comfirm/face/change`, {}, { x, y, width, height, type, path, bucket }), { x, y, width, height, type, path, bucket }, 'post');
//pc 获取作品集接口

export const getAlbumWork = (sub) => fetch(signature(apiUrl + `/rest/v3/pro/work/sub${sub}`, {}, { sub }));

//pc 获取摄影师的资料
export const getPhotoGrapherDetail = (sub) => fetch(signature(apiUrl + `/rest/v3/pro/detail/sub${sub}`, {}, { sub }));

//PC用户登出接口　！！测试环境没用

export const pcloginOut = (jsessionId) => fetch(signature(apiUrl + `/rest/v3/logout/jsessionId${jsessionId}`, {}, { jsessionId }));

//PC 登录验证
export const pcLoginVertify = (sub) => fetch(signature(apiUrl + `/rest/v3/validate/login/sub${sub}`, {}, { sub }));

//获取审核页分类信息
export const getAuditingPageSep = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/retouch/detail/albumIdN${albumIdN}`, {}, { albumIdN }));
// 用户上报
export const userTrack = (d) => fetch(signature(albumRevisionUrl + `/rest/v3/fd/r`, {}, { d }), { d }, 'post');

//获取审核页面数据接口
// export const getAuditingPageActp = (display, albumIdN, sepId, maxReplaceTime, cnt, sort, pc) => fetch(signature(apiUrl + `/rest/v3/auditing/actrp/display${display}/albumIdN${albumIdN}/sepId${sepId}/maxReplaceTime${maxReplaceTime}/cnt${cnt}/sort${sort}/pc${pc}`, {}, { display, albumIdN, sepId, maxReplaceTime, cnt, sort, pc }));

//获取审核照片最新照片个数
// export const getAuditingCheck = (albumIdN, display, sepJson, nowSepId) => fetch(signature(apiUrl + `/rest/v3/auditing/check`, {}, { albumIdN, display, sepJson, nowSepId }), { albumIdN, display, sepJson, nowSepId }, 'post');

//修改图片是否显示
export const setDisplay = (pc, display, albumIdN) => fetch(signature(apiUrl + `/rest/v3/auditing/displayMd`, {}, { pc, display, albumIdN }), { pc, display, albumIdN }, 'post');

//获取下载链接的md5密码
export const getDownloadPassword = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/download/md5/pwd/get/albumIdN${albumIdN}`, {}, { albumIdN }));

//获取打包相册信息
export const getDownloadInfo = (albumIdN, pwd) => fetch(signature(apiUrl + `/rest/v3/download/album/package/info/albumIdN${albumIdN}/pwd${pwd}`, {}, { albumIdN, pwd }));

//获取下载链接详情（新增分页）
export const getDownloadDetailsNew = (albumIdN, pwd, pag, cnt, type) => fetch(signature(apiUrl + `/rest/v3/download/album/package/init/albumIdN${albumIdN}/pwd${pwd}/pag${pag}/cnt${cnt}/type${type}`, {}, { albumIdN, pwd, pag, cnt, type }));

//pc 下载页面　轮询 获取相册打包状态

export const getPackageState = (packageId) => fetch(signature(apiUrl + `/rest/v3/download/state/check/packageId${packageId}`, {}, { packageId }));

//pc 下载页面　打包

export const setPackageState = (packageId, pwd, type) => fetch(signature(apiUrl + `/rest/v3/download/state/do/packageId${packageId}/pwd${pwd}/type${type}`, {}, { packageId, pwd, type }));

//pc 下载页面　打包 l冷冻相册
export const setFreezePackageState = (packageId, pwd, type) => fetch(signature(apiUrl + `/rest/v3/download/state/freeze/do/packageId${packageId}/pwd${pwd}/type${type}`, {}, { packageId, pwd, type }));


//PC 案例页面通过标签获取案例信息
export const getTagsCaseInfo = (typeIds, labelIds, pageNum, pageSize) => fetch(signature(apiUrl + `/rest/v3/label/album/info`, {}, { typeIds, labelIds, pageNum, pageSize }), { typeIds, labelIds, pageNum, pageSize }, 'post');

//签约摄影师　基本信息提交

export const postSignPhotographerInfo = (obj) => {
        let goodList = [];
        Object.keys(obj.selectArea).forEach(key => {
            if (obj.selectArea[key] == true) {
                goodList.push(key)
            }
        })
        let name, age, wechatNum, model, lens, isThreeBigLens, beginWorkYear, goodAt, experience, hasDevice;
        name = obj.name;
        age = obj.age;
        wechatNum = obj.wechatID;
        model = obj.modelList.join("%&%");
        lens = obj.lensList.join("%&%");
        isThreeBigLens = Number(obj.hasLens);
        beginWorkYear = Number(obj.years);
        goodAt = goodList.join(";");
        experience = obj.details;
        hasDevice = obj.hasDevice;
        return fetch(signature(apiUrl + `/rest/v3/sign/pg/info/submit`, {}, { name, age, wechatNum, model, lens, isThreeBigLens, beginWorkYear, goodAt, experience, hasDevice }), { name, age, wechatNum: encodeURIComponent(wechatNum), model: encodeURIComponent(model), lens: encodeURIComponent(lens), isThreeBigLens, beginWorkYear, goodAt, experience: encodeURIComponent(experience), hasDevice }, 'post');
    }
    //签约摄影师　审核状态
export const getAuditingState = (type) => fetch(signature(apiUrl + `/rest/v3/sign/state/query`,{}, { type }), { type }, 'post');


// 签约摄影师　摄像，短视频的作品标签库
// export const getListTags = () => fetch(signature(apiUrl + `/rest/v3/sign/video/listTags`, {}, {}));

// 摄像，短视频的作品用户自定义标签库新增
// export const addNewTag = (tag) => fetch(signature(apiUrl + `/rest/v3/sign/video/addNewTag/tag${tag}`, {}, {tag}));

// 视频直播简历作品创建
// export const createLiveVideoResume = (title, url, uuid, categoryId) =>{
//   let liveurl = encodeURIComponent(url)
//   return fetch(signature(apiUrl + `/rest/v3/pro/live/create`, {}, { title, url, uuid, categoryId}), { title, url:liveurl, uuid, categoryId }, 'post');
// }
//相册不区分参与与创建
export const searchAlbum = (title) => fetch(signature(apiUrl + `/rest/v3/album/user/search/title${title}`, {}, { title }));

// 新的按标题搜索相册
export const titleSearchAlbum = (title, cnt, pag) => fetch(signature(apiUrl + `/rest/v3/album/user/search/change/title${title}/cnt${cnt}/pag${pag}`, {}, {title, cnt, pag}));

//获取相册基本信息
export const getBasicAlbumInfo = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/info/albumIdN${albumIdN}`, {}, { albumIdN }));

//相册分类的显示与隐藏
export const albumClassShow = (albumIdN, sepIdN, display) => fetch(signature(albumRevisionUrl + `/rest/v3/sep/set/displaySepSetting`, {}, { albumIdN, sepIdN, display }), {albumIdN, sepIdN, display}, 'post');

//获取第三方接口使用的code密钥
export const getSepCode = (albumIdN, sepIdN) => fetch(signature(apiUrl + `/rest/v3/third/code/acquice/albumIdN${albumIdN}/sepIdN${sepIdN}`, {}, { albumIdN, sepIdN }));

//pc相册设置 获取常用菜单
export const getCommonMenu = () => fetch(signature(albumRevisionUrl + `/rest/v3/user/queryMenuCommonSetting`));

//pc相册设置 设置常用菜单
export const setCommonMenu = (type, state) => fetch(signature(albumRevisionUrl + `/rest/v3/user/set/setAlbumMenuFrequently`, {}, { type, state }), {type, state}, 'post');

//pc相册设置　相册基本信息提交
// export const postBasicAlbumInfo = (albumIdN, albumCode, albumAddr, title, style, addrDetail, isDefault, uuid = "", GCJLocation, WGSLocation = "", dsc, labelIds, startDate, endDate, englishTitle, showNavigation) => {
//     let encodeAlbumAddr = encodeURIComponent(albumAddr);
//     let encodeTitle = encodeURIComponent(title);
//     let encodeAddrDetail = encodeURIComponent(addrDetail);
//     let encodedsc = encodeURIComponent(dsc)
//     let encodedEnglishTitle = encodeURIComponent(englishTitle)
//     return fetch(signature(apiUrl + `/rest/v3/album/pc/base/info/edit`, {}, { albumIdN, albumCode, albumAddr, title, style, addrDetail, isDefault, uuid, GCJLocation, WGSLocation, dsc, labelIds, startDate, endDate, englishTitle, showNavigation }), { albumIdN, albumCode, albumAddr: encodeAlbumAddr, title: encodeTitle, style, addrDetail: encodeAddrDetail, isDefault, uuid, GCJLocation, WGSLocation, dsc: encodedsc, labelIds, startDate, endDate, englishTitle: encodedEnglishTitle, showNavigation }, 'post');
// }

//pc相册设置 v3 开关设置

export const postAlbumSwitchSetting = (albumIdN, pcSwitchJson) => fetch(signature(apiUrl + `/rest/v3/album/pc/switch`, {}, { albumIdN, pcSwitchJson }), { albumIdN, pcSwitchJson }, 'post');

// pc 设置侧边栏页面
export const saveSlideSetting = (albumIdN, isToolsSpread, pcSwitchJson) => fetch(signature(apiUrl + `/rest/v3/album/pc/second/sidebarSetting`, {}, { albumIdN, isToolsSpread, pcSwitchJson }), { albumIdN, isToolsSpread, pcSwitchJson }, 'post');

//pc相册设置 互动上传 同步数据到喔图账号
export const syncAlbumData = (phone) => fetch(signature(apiUrl + `/rest/v3/user/syn/data`, {}, { phone }), { phone }, 'post');

//pc相册设置 互动上传 微信授权登录
export const wxLogin = (code, appId) => fetch(signature(apiUrl + `/rest/v3/wechat/login/online`, {}, { code, appId }), { code, appId }, 'post');

//pc相册设置 互动上传 微信授权登录
export const startSyncData = (phone, token) => fetch(signature(apiUrl + `/rest/v3/user/syn/data`, {}, { phone }, 0, token), { phone }, 'post');

//pc相册设置 互动上传 获取或者创建一个我的相册
export const createNewAlbum = (title, startDate, endDate, addr, tag, auth, uploadPwd, pri, visitPwd, canShare, albumIdN = 0) => fetch(signature(apiUrl + `/rest/v3/cloud/album/base/setting`, {}, { title, startDate, endDate, tag, auth, pri, canShare, albumIdN }), { title, startDate, endDate, addr, tag, auth, uploadPwd, pri, visitPwd, canShare, albumIdN }, 'post');

//pc相册设置 互动上传 检测同步数据到喔图账号状态
export const pollingSyncAlbumData = (wxUserId) => fetch(signature(apiUrl + `/rest/v3/cloud/state/check/change/wxUserId${wxUserId}`, {}, { wxUserId }));

//pc相册设置 互动上传开关设置
export const interactiveUploadSetting = (albumIdN, bindingAlbumIdN, isInteractive) => fetch(signature(apiUrl + `/rest/v3/album/bind/cloud/album`, {}, { albumIdN, bindingAlbumIdN, isInteractive }), { albumIdN, bindingAlbumIdN, isInteractive }, 'post');

//pc相册设置 获取用户的aiphoto会员信息  并 返回是否为aiphoto用户信息
export const getAiphotoUserInfo = () => fetch(signature(apiUrl + `/rest/v3/user/cloud/info`, {}, {}));

//pc相册设置 云相册获取相册数据
export const getCloudAlbumList = (type, albumIdN, cnt) => fetch(signature(apiUrl + `/rest/v3/cloud/album/simple/info/type${type}/albumIdN${albumIdN}/cnt${cnt}`, {}, { type, albumIdN, cnt }));

//pc相册设置　分类设置
export const postAlbumClassSetting = (albumIdN, sepStr) => {
        sepStr = JSON.stringify(sepStr)
        return fetch(signature(albumRevisionUrl + `/rest/v3/sep/set/sortSep`, {}, { albumIdN, sepStr }), { albumIdN, sepStr }, 'post');
    }

// pc相册设置 分类设置 新增、编辑、删除分类
// export const postClassChangeSetting = (albumIdN, sepIdN, type, name = '') => {
//     let encodeURIComponentName = encodeURIComponent(name);
//     return fetch(signature(apiUrl + `/rest/v3/save/sepN`, {}, { albumIdN, sepIdN, type, name }), { albumIdN, sepIdN, type, name: encodeURIComponentName }, 'post');
// }

    //pc相册设置　私密性设置
// export const postAlbumSelfSetting = (albumIdN, level, pwd, guestTitle, guestTips, guestTitleEn, guestTipsEn, passwdTitle, passwdTips, passwdTitleEn, passwdTipsEn) => fetch(signature(apiUrl + `/rest/v3/album/pc/privacy/set`, {}, { albumIdN, level, pwd }), { albumIdN, level, pwd, guestTitle, guestTips, guestTitleEn, guestTipsEn, passwdTitle, passwdTips, passwdTitleEn, passwdTipsEn }, 'post');

//pc相册设置　进入设置页面获取对应相册指定权限的用户信息
// export const getAlbumPersonInfo = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/owner/albumIdN${albumIdN}`, {}, { albumIdN }));

//pc相册设置　pc摄影师修图师设置 改动后
// export const postAlbumPersonSetting = (albumIdN, isProSetting, isRetouchSetting, isAuditSetting, ownerJson) => fetch(signature(apiUrl + `/rest/v3/act/autho/pc/change`, {}, { albumIdN, isProSetting, isRetouchSetting, isAuditSetting, ownerJson }), { albumIdN, isProSetting, isRetouchSetting, isAuditSetting, ownerJson }, 'post');

//pc相册设置　pc摄影师修图师设置
// export const postAlbumRetouchSetting = (albumIdN, ownerJson) => fetch(signature(apiUrl + `/rest/v3/act/autho/change`, {}, { albumIdN, ownerJson }), { albumIdN, ownerJson }, 'post');

//pc相册设置　查询最近添加的修图师
// export const getLaterlyRetoucher = (userIdsJson, page, cnt) => fetch(signature(apiUrl + `/rest/v3/album/retouch/HistoryRecord`, {}, { userIdsJson, page, cnt }), { userIdsJson, page, cnt }, 'post');

//pc相册设置　查询最近添加的修图师 审核员 摄影师 通用接口
// export const getPersonHistory = (userIdsJson, page, cnt, type) => fetch(signature(apiUrl + `/rest/v3/album/retouch/getHistoryRecord`, {}, { userIdsJson, page, cnt, type }), { userIdsJson, page, cnt, type }, 'post');

// pc设置九宫格封面图
// export const sudokuCoverSetting = (albumIdN, uuid) => fetch(signature(apiUrl + `/rest/v3/pc/jigsaw/setting`, {}, { albumIdN, uuid }), { albumIdN, uuid }, 'post');


//pc相册设置　分享封面保存设置

export const postAlbumShareSetting = (albumIdN, shareTitle, desc, uuid, isCustomShareCover, isReset = 0) => {
    let encodeDesc = encodeURIComponent(desc);
    return fetch(signature(apiUrl + `/rest/v3/album/pc/share/setting`, {}, { albumIdN, shareTitle, desc, uuid, isCustomShareCover, isReset }), { albumIdN, shareTitle: encodeURIComponent(shareTitle), desc: encodeDesc, uuid, isCustomShareCover, isReset }, 'post');
}

//pc相册设置　banner设置　进入该页面获取banner相关信息

// export const getAlbumBannerInfo = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/pc/banner/get/albumIdN${albumIdN}`, {}, { albumIdN }));

//pc相册设置　设置默认

export const defaultSettting = ({ albumIdN, isLanguage, displayType, isToolsSpread, sortType, isCodeOpen, isLanguageOpen, isDisplayTypeOpen  }) => fetch(
    signature(apiUrl + `/rest/v3/album/pc/second/setting`, {}, { albumIdN, isLanguage, displayType, isToolsSpread, sortType, isCodeOpen, isLanguageOpen, isDisplayTypeOpen }), { albumIdN, isLanguage, displayType, isToolsSpread, sortType, isCodeOpen, isLanguageOpen, isDisplayTypeOpen },
    'post');

export const setSlideSetting = (key, value, albumId) => fetch(signature(apiUrl + `/rest/v3/album/create/more`, {}, { key, value, albumId }), { key, value, albumId });


//pc相册设置　banner设置　获取banner图片id

export const getAlbumBannerId = (albumIdN, fileName = 0, fileSize = 0, uuid = 0) => fetch(signature(apiUrl + `/rest/v3/album/pc/banner/forId`, {}, { albumIdN, fileName, fileSize, uuid }), { albumIdN, fileName, fileSize, uuid }, 'post');

//PC 上传视频或者banner计算费用
export const getAlbumBannerPrice = (albumIdN, size, type = 1) => fetch(signature(apiUrl + `/rest/v3/app/video/c/albumIdN${albumIdN}/size${size}/type${type}`, {}, { albumIdN, size, type }));

//pc相册设置　banner设置　保存已有的链接信息

// export const postAlbumBannerSetting = (albumIdN, bannerVersion, bannerJson, titleDisplay, bannerTitle, isBanner, bannerSize, uuid, showVisitors, showTime, showAddress, type) => {
//     let encodeBannerTitle = encodeURIComponent(bannerTitle);
//     let encodeBannerJson = encodeURIComponent(bannerJson)
//     return fetch(signature(apiUrl + `/rest/v3/album/pc/banner/photo/comfirm`, {}, { albumIdN, bannerVersion, bannerJson, titleDisplay, bannerTitle, isBanner, bannerSize, uuid, showVisitors, showTime, showAddress, type }), { albumIdN, bannerVersion, bannerJson: encodeBannerJson, titleDisplay, bannerTitle: encodeURIComponent(bannerTitle), isBanner, bannerSize, uuid, showVisitors, showTime, showAddress, type }, 'post');
// }

// pc 相册设置 详情设置  信息卡片
// export const getCardDetailInfo = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/setting/acquire/detail/albumIdN${albumIdN}`, {}, { albumIdN }));

//获取海报和加载图详情
export const getPosterInfo = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/poster/loading/detail/albumIdN${albumIdN}`, {}, { albumIdN }));

// pc相册设置  启动页设置
export const postAlbumStartUpInfo = (albumIdN, isPoster, isCustomPoster, isCustomLoading, title, subTitle, posterTime, place, isPosterNew, buttonType, posterButtonEnterTxt, isPosterCountdownOpen, posterShowStyle, startupTime) => {
    let encodeTitle = encodeURIComponent(title);
    let encodeSubTitle = encodeURIComponent(subTitle);
    let encodePosterTime = encodeURIComponent(posterTime);
    let encodePlace = encodeURIComponent(place);
    return fetch(signature(apiUrl + `/rest/v3/album/pc/start/setting`, {}, { albumIdN, isPoster, isCustomPoster, isCustomLoading, title, subTitle, posterTime, place, isPosterNew, buttonType, posterButtonEnterTxt, isPosterCountdownOpen, posterShowStyle, startupTime }), { albumIdN, isPoster, isCustomPoster, isCustomLoading, title: encodeTitle, subTitle: encodeSubTitle, posterTime: encodePosterTime, place: encodePlace, isPosterNew, buttonType, posterButtonEnterTxt, isPosterCountdownOpen, posterShowStyle, startupTime }, 'post');
}

// pc底部广告设置
// export const postBottomPublicSetting = (isFooter = '', albumIdN = '', footerName = '', url = '', type = '', buttonName = '', sideAdShowUpOnce = '', footerType = '') => {
//     let encodeFooterName = encodeURIComponent(footerName);
//     let encodeUrl = encodeURIComponent(url);
//     let encodeButtonName = encodeURIComponent(buttonName);
//     return fetch(signature(apiUrl + `/rest/v3/album/pc/footer/change/setting`, {}, { isFooter, albumIdN, footerName, url, type, buttonName, sideAdShowUpOnce, footerType }), { isFooter, albumIdN, footerName: encodeFooterName, url: encodeUrl, type, buttonName: encodeButtonName, sideAdShowUpOnce, footerType }, 'post');
// }

// PC获取指定相册下的所有主办方详情　含logos
// export const getSponsorInfo = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/sponsor/logo/info/get/albumIdN${albumIdN}`, {}, { albumIdN }));

//主办方新增中间表记录，返回新的中间表id mbId　

// export const getSponsorDetailsId = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/pc/album/sponsor/get`, {}, { albumIdN }), { albumIdN }, 'post');

//主办方新增中间表记录，返回新的图片记录id sponsorId　

export const getSponsorCardId = (albumIdN, mbId) => fetch(signature(apiUrl + `/rest/v3/pc/album/sponsor/real/get`, {}, { albumIdN, mbId }), { albumIdN, mbId }, 'post');




//pc 设置- 详情卡片保存
// export const saveSettingInfoCard = (albumIdN, jsones) => fetch(signature(apiUrl + `/rest/v3/album/setting/detail`, {}, { albumIdN }), { albumIdN, json: encodeURIComponent(jsones) }, 'post');

//pc设置-点赞气泡设置
// export const saveBubbleSetting = (albumIdN, isGoodOpen, goodBubbleType, json) => fetch(signature(apiUrl + `/rest/v3/album/good/bubble/setting`, {}, { albumIdN, isGoodOpen, goodBubbleType, json }), { albumIdN, isGoodOpen, goodBubbleType, json }, 'post');

//pc设置-点赞气泡设置详情获取
// export const getBubbleSetting = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/good/bubble/info/albumIdN${albumIdN}`, {}, { albumIdN }));

//获取水印id接口
// export const getWaterUploadId = (count, type = 0) => fetch(signature(apiUrl + `/rest/v3/watermark/upload`, {}, { count, type }), { count, type }, 'post');

//获取设置水印信息和水印信息
export const getWaterInfo = (albumIdN, type = 0) => fetch(signature(albumRevisionUrl + `/rest/v3/album/query/queryWatermarkSetting/albumIdN${albumIdN}/type${type}`, {}, { albumIdN, type }));

//删除水印图片　
export const deleteWater = (id) => fetch(signature(apiUrl + `/rest/v3/watermark/remove/pic/id${id}`, {}, { id }));




// 获取自定义菜单列表
export const getCustomNavList = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/custom/menu/list`, {}, { albumIdN }), { albumIdN }, 'post');


// 创建自定义菜单
export const createCustomNavList = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/custom/menu/create`, {}, { albumIdN }), { albumIdN }, 'post');

// 持久化自定义菜单图片路径

export const saveTmpPhoto = (albumIdN, menuId, json) => fetch(signature(apiUrl + `/rest/v3/album/save/custom/menu/photo`, {}, { albumIdN, menuId, json }), { albumIdN, menuId,  json: encodeURIComponent(json)}, 'post');


// 保存自定义菜单名称或外链
export const saveNavCustomName = (albumIdN, key, value, menuId) => fetch(signature(apiUrl + `/rest/v3/album/custom/menu/key/setting`, {}, { albumIdN, key, value, menuId }), { albumIdN, key, value: encodeURIComponent(value), menuId }, 'post');


// 自定义菜单开关设置
export const saveNavCustomSwitch = (albumIdN, menuId, isOpen) => fetch(signature(apiUrl + `/rest/v3/album/switch/custom/menu`, {}, { albumIdN, menuId, isOpen }), { albumIdN, menuId, isOpen }, 'post');

// 删除自定义菜单
export const deleteCustomNav = (albumIdN, menuId) => fetch(signature(apiUrl + `/rest/v3/album/delete/custom/menu`, {}, { albumIdN, menuId }), { albumIdN, menuId }, 'post');


// 保存自定义菜单排序
export const saveSortNav = (albumIdN, json) => fetch(signature(apiUrl + `/rest/v3/album/sort/custom/menu`, {}, { albumIdN, json }), { albumIdN, json }, 'post');


// 保存富文本内容
export const saveRichTxtContent = (albumIdN, menuId, uuid) => fetch(signature(apiUrl + `/rest/v3/album/save/custom/menu/html/confirm`, {}, { albumIdN, menuId, uuid }), { albumIdN, menuId, uuid }, 'post');



//pc　生成实时预览图

// export const getWaterPreview = (albumIdN, info, type = 0) => fetch(signature(apiUrl + `/rest/v3/pc/watermark/preview/create`, {}, { albumIdN, info, type }), { albumIdN, info, type }, 'post');

//pc设置　确认

// export const confirmWaterPreview = (albumIdN, info) => fetch(signature(apiUrl + `/rest/v3/pc/watermark/confirm`, {}, { albumIdN, info }), { albumIdN, info }, 'post');


//统一的pc短信接口　与腾讯的验证码组件耦合
// export const sendSMS = (ticket, vc, vcodeType, phone) => fetch(signature(apiUrl + `/rest/v3/pc/validate/send/sms`, {}, { ticket, vc, vcodeType, phone }), { ticket, vc, vcodeType, phone }, 'post');

// 统一的pc短信验证码登陆接口 腾讯新版防水墙
export const newSendSMS = (ticket, vc, vcodeType, phone, globalCode) => fetch(signature(apiUrl + `/rest/v3/new/validate/send/sms`, {}, { ticket, vc, vcodeType, phone, globalCode }), { ticket, vc, vcodeType, phone,globalCode }, 'post');

//用户通过验证码登陆
// export const verificationLogin = (phone, sms) => fetch(signature(apiUrl + `/rest/v3/user/login/by/sms`, {}, { phone, sms }), { phone, sms }, 'post');

//用户快速注册
// export const quickRegister = (phone, sms , pwd , vcodeType, nick) => fetch(signature(apiUrl + `/rest/v3/user/register/simple`, {}, { phone, sms , pwd , vcodeType, nick}), { phone, sms , pwd , vcodeType, nick}, 'post');

//用户重置密码
// export const changePassword = (phone, sms , pwd) => fetch(signature(apiUrl + `/rest/v3/user/pwd/reset`, {}, { phone, sms , pwd}), { phone, sms , pwd}, 'post');

//进入注册页面访问该接口（生成ps_user表记录）
// export const getLoginUserID = () => fetch(signature(apiUrl + `/rest/v3/pc/pro/regist`, {}, {}));

//注册获取上传头像权限
// export const getLoginUploadAuth = (id) => fetch(signature(apiUrl + `/rest/v3/pc/pro/ossauthforfaceupload`, {}, { id }), { id }, 'post');

//注册 上传用户头像
// export const setUploadHead = (id, x, y, width, height) => fetch(signature(apiUrl + `/rest/v3/pc/pro/face/confirm`, {}, { id, x, y, width, height }), { id, x, y, width, height }, 'post');

//提交注册
// export const postRegister = (id, sub, type, addr, addrCode, nick, tel, pwd, verifyCode) => fetch(signature(apiUrl + `/rest/v3/pc/pro/doregist`, {}, { id, sub, type, addr, addrCode, nick, tel, pwd, verifyCode }), { id, sub, type, addr, addrCode, nick, tel, pwd, verifyCode }, 'post');

//检测最新的app包
export const getNewestApp = (type = 0) => fetch(signature(apiUrl + `/rest/v3/app/update`, {}, { type }), { type }, 'post');

//查询相册下所有分类 v3
export const searchAllClass = (albumIdN) => fetch(signature(gsUrl + `/rest/v3/album/query/queryAlbumSeperate`, {}, { albumIdN }), { albumIdN }, 'post');

//获取相册某一分类下的图片 v3
// export const getClassAlbumList = (albumIdN, groupId, photoId, cnt) => fetch(signature(apiUrl + `/rest/v3/pc/album/sep/photos`, {}, { albumIdN, groupId, photoId, cnt }), { albumIdN, groupId, photoId, cnt }, 'post');

//pc图片上传 发送文件名 获取图片id   v3
// export const sendAlbumName = (albumIdN, sepId, videoJson) => {
//     let encodeVideoJson = encodeURIComponent(videoJson)
//     return fetch(signature(apiUrl + `/rest/v3/pc/newedit/require/pid`, {}, { albumIdN, sepId }), { albumIdN, sepId, videoJson: encodeVideoJson }, 'post');
// }

// PC上传页检测同名文件是否已经上传
// export const checkPhotoFile = (fileNames, albumIdN, sepId) => fetch(signature(apiUrl + `/rest/v3/photo/checkPhotoFile`, {}, { albumIdN, sepId}), {fileNames, albumIdN, sepId}, 'post');

// 标记图片信息
export const setImgInfo = (albumIdN, info, type, pc) => fetch(signature(apiUrl + `/rest/v3/pc/make/info`, {}, { albumIdN, info, type, pc }), { albumIdN, info, type, pc }, 'post');

//pc新相册编辑  上传状态确认 v3
// export const checkoutAlbumUpload = (photoIds, sepId) => fetch(signature(apiUrl + `/rest/v3/pc/newedit/check/upload`, {}, { photoIds, sepId }), { photoIds, sepId }, 'post');

//pc新相册编辑  删除图片 v3
// export const deleteLocalUpload = (photoId, albumIdN, sepId) => fetch(signature(apiUrl + `/rest/v3/pc/newedit/photo/delete`, {}, { photoId, albumIdN, sepId }), { photoId, albumIdN, sepId }, 'post');

//pc新相册编辑  旋转图片 v3
// export const rotateLocalUpload = (photoId) => fetch(signature(apiUrl + `/rest/v3/pc/newedit/photo/rotate`, {}, { photoId }), { photoId }, 'post');

//pc新相册编辑  设置封面 v3
export const setCoverLocalUpload = (photoId, albumIdN, groupId) => fetch(signature(apiUrl + `/rest/v3/pc/newedit/album/cover`, {}, { photoId, albumIdN, groupId }), { photoId, albumIdN, groupId }, 'post');

//pc新相册编辑 根据视频大小返回费用信息 v3
// export const getPriceofVideo = (videoSize)=> fetch(signature(apiUrl + `/rest/v3/pc/newedit/video/cost`,{}, {videoSize}), {videoSize}, 'post');

//PC 统计埋点
export const sendUC = (type) => fetch(signature(apiUrl + `/rest/v3/view/time/cal`, {}, { type }), { type }, 'post');

//PC 本地上传视频获取视频费用详情
// export const getPriceofVideo = (albumIdN, videoJson) => fetch(signature(apiUrl + `/rest/v3/pc/video/c`, {}, { albumIdN }), { albumIdN, videoJson }, 'post');

//pc 友情链接获取

export const getFreindChain = (origin) => fetch(signature(apiUrl + `/rest/v3/pc/footer/info/origin${origin}`, {}, { origin }));



//个人资料获取我的存储分页
export const getMyStorageList = (minTime, maxTime, origin, sizeMin, sizeMax, isEnd, sortType, pag, cnt, title) => fetch(signature(cloudStorageAPI + `/rest/v4/cloud/storage/user/album/cs/list`, {}, { minTime, maxTime, origin, sizeMin, sizeMax, isEnd, sortType, pag, cnt }), { minTime, maxTime, origin, sizeMin, sizeMax, isEnd, sortType, pag, cnt, title }, 'post');

// 获取已存储空间大小
export const getStorageSize = () => fetch(signature(cloudStorageAPI + `/rest/v4/cloud/storage/user/alltuu/size/cal`, {}, {}));

// 删除相册
// export const deleteStorageAlbum = (ids) => fetch(signature(apiUrl + `/rest/v3/album/batchDelete`, {}, { ids }), { ids }, 'post');

// 相册还原
export const restoreAlbum = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/restore`, {}, { albumIdN }), { albumIdN }, 'post');

// 彻底删除
// export const thoroughDeleteAlbum = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/thoroughDelete`, {}, { albumIdN }), { albumIdN }, 'post');


// 回收站列表
export const getRecycleList = (pag, cnt, title, startTime, endTime) => fetch(signature(apiUrl + `/rest/v3/album/pc/restore/pag${pag}/cnt${cnt}/title${title}/startTime${startTime}/endTime${endTime}`, {}, { pag, cnt, title, startTime, endTime }));


//pc 审核页面　查询视频费用的接口
// export const getAuditingVideoPrice = (albumIdN, photoId) => fetch(signature(apiUrl + `/rest/v3/audit/video/c/albumIdN${albumIdN}/photoId${photoId}`, {}, { albumIdN, photoId }));

//PC 微信小程序授权申请上传表单
export const wechatSubmitForm = (appName, name, nickname, phone) => fetch(signature(apiUrl + `/rest/v3/app/authfile/upload`, {}, { phone }), { appName, name, nickname, phone }, 'post');

//pc 底部广告设置
// export const setEndingSetting = (albumIdN, title, introduce, type, isEnding) => {
//     let encodeTitle = encodeURIComponent(title);
//     let encodeIntro = encodeURIComponent(introduce);
//     return fetch(signature(apiUrl + `/rest/v3/pc/album/ending/set`, {}, { albumIdN, title, introduce, type, isEnding }), { albumIdN, title: encodeTitle, introduce: encodeIntro, type, isEnding }, 'post');
// }


//修图页面 下载照片
export const confirmDownloadRetouch = (albumIdN, photoId) => fetch(signature(retouchBaseUrl + `/rest/v3/retouch/photo/download`, {}, { albumIdN, photoId }), { albumIdN, photoId }, 'post');


//获取相册风格
// export const getAlbumStyle = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/list/style/change/albumIdN${albumIdN}`, {}, {albumIdN}));




//修图页面 评价摄影师提交 非官方相册
export const postPhgrapherEvaluate = (albumIdN, json) => fetch(signature(retouchBaseUrl + `/rest/v3/retouch/evaluate/submit`, {}, { albumIdN }), { albumIdN, json }, 'post');







//获取视频提供商 视频ID
// export const getBecomVerID = (fileName, type, categoryId) => fetch(signature(apiUrl + `/rest/v3/sign/video/acquire/id`, {}, { fileName, type, categoryId }), { fileName, type, categoryId }, 'post');

//获取视频列表
// export const getVideoerList = () => fetch(signature(apiUrl + `/rest/v3/sign/video/list`, {}, {}));

//m ossApi
export const ossApi = a => fetch(a);

// 个人资料  简历页面  获取个人信息
export const getPotoGrapherInfo = (sub = '') => fetch(signature(apiUrl + `/rest/v3/pg/intro/detail/sub${sub}`, {}, { sub }));

// 创建作品集
export const createWorks = (type) => fetch(signature(apiUrl + `/rest/v3/pg/create/work`, {}, {type}), {type}, 'post');
// 编辑作品集
export const editWorks = ({ workId, photoNames, uploadOrigin, count }) => fetch(signature(
        apiUrl + `/rest/v3/pg/work`, {}, { workId, photoNames, uploadOrigin, count }), { workId, photoNames: encodeURIComponent(photoNames), uploadOrigin, count },
    'post');

// 上传作品集
export const deleteWorks = (workId) => fetch(signature(apiUrl + `/rest/v3/pg/del/work`, {}, { workId }), { workId }, 'post');
// 上传图片 获取Oss权限
export const upLoadImg = (contentId) => fetch(signature(apiUrl + `/rest/v3/sign/pro/upload/auth`, {}, { contentId }), { contentId }, 'post');
// 个人简历 替换头像
export const replaceFaceImg = (type) => fetch(signature(apiUrl + `/rest/v3/auth/type${type}`, {}, { type }));

// 签约直播封面 上传权限
// export const liveVideoUpload = () => fetch(signature(apiUrl + `/rest/v3/sign/pro/live/upload/auth`, {}, {}),{}, 'post');

// 签约状态获取
// export const getProSignStatus = () => fetch(signature(apiUrl + `/rest/v3/sign/proSign/getStatus`, {}, {}),{}, 'post');

// 获取作品集详情
export const getWorksDeatil = (workId, code) => fetch(signature(apiUrl + `/rest/v3/pg/work/detail`, {}, { workId, code }), { workId, code }, 'post');
// 发布作品集
export const publishWorks = ({ workId, title, ids }) => fetch(signature(
        apiUrl + `/rest/v3/pg/work/publish/change`, {}, { workId, title, ids }), { workId, title: encodeURIComponent(title), ids },
    'post');
// export const getAlbumDetailInfo = (albumIdN, pwd = "") => fetch(signature(apiUrl + `/rest/v3/album/pc/direct/album/info/albumIdN${albumIdN}/pwd${pwd}`, {}, { albumIdN, pwd }));

//pc直播頁面 获取图片
// export const getAlbumPhotoList = (albumIdN, cnt, id, type, sort, sepId, pwd) => fetch(signature(apiUrl + `/rest/v3/album/pc/direct/photo/info/albumIdN${albumIdN}/sort${sort}/cnt${cnt}/id${id}/type${type}/sepId${sepId}/pwd${pwd}`, {}, { albumIdN, cnt, id, type, sort, sepId, pwd }));

// 个人资料页面 闪传服务- 获取商品信息
export const postGetBoxInfo = () => fetch(signature(apiUrl + `/rest/v3/pc/order/info`, {}, {}), {}, 'post')
    // 个人资料页面 闪传服务-生成订单信息
export const postCreateOrder = ({ goodsId, albumIdN, count, suit, accessory }) => fetch(
        signature(apiUrl + `/rest/v3/custom/build`, {}, { goodsId, albumIdN, count, suit, accessory }), { goodsId, albumIdN, count, suit, accessory },
        'post')
    // 个人资料页面 闪传服务-支付订单接口
export const postPayOrder = (orderId) => fetch(signature(apiUrl + `/rest/v3/custom/payorder`, {}, { orderId }), { orderId }, 'post')
    // 个人资料页面 - 个人资料 - 获取用户信息
export const personDataGetInfo = () => fetch(signature(apiUrl + `/rest/v3/user/get/personal/info`, {}, {}))
    // 个人资料页面 - 安全中心 - 保存修改用户信息
export const saveEditUserInfo = ({ nick, sex, addr = "", addrCode, name, phone, alipayId, phoneVerify, model, lens, dsc }) => fetch(
        signature(apiUrl + `/rest/v3/user/mod/submit`, {}, { nick, sex, addr, addrCode, name, phone, alipayId, phoneVerify, model, lens, dsc }), { nick: encodeURIComponent(nick), sex, addr, addrCode, name: encodeURIComponent(name), phone, alipayId, phoneVerify, model: encodeURIComponent(model), lens: encodeURIComponent(lens), dsc: encodeURIComponent(dsc) },
        'post')
    // 个人资料页面 - 安全中心 - 修改密码
// export const editPwd = ({ oldPwd, newPwd }) => fetch(signature(apiUrl + `/rest/v3/app/user/modipass`, {}, { oldPwd, newPwd }), { oldPwd: encodeURIComponent(oldPwd), newPwd: encodeURIComponent(newPwd) }, 'post')

    // 个人资料页面 - 订单获取
export const getOrderList = ({ page, count }) => fetch(signature(apiUrl + `/rest/v3/pc/order/list/page${page}/count${count}`, {}, { page, count }))
    // 我的订单页面 - 获取订单列表
export const searchOrderList = (title, creatDateStart, creatDateEnd, offset, limit, type, orderType) => fetch(signature(apiUrl + `/rest/v3/order/new/list`, {}, { creatDateStart, creatDateEnd, offset, limit, type }), { title, creatDateStart, creatDateEnd, offset, limit, type, orderType }, 'post')
    // 我的订单页面 - 获取订单详情
export const getOrderDetile = (orderIdStr) => fetch(signature(apiUrl + `/rest/v3/order/new/detail/orderIdStr${orderIdStr}`, {}, { orderIdStr }))
    // 我的订单页面 - 确定取消订单
export const cancelOrder = (orderIdStr) => fetch(signature(apiUrl + `/rest/v3/m/order/retouch/cancel`, {}, { orderIdStr }), { orderIdStr }, 'post')


//PC定制版个人主页-获取相册个数
export const getCutsomAlbumInfo = (sub) => fetch(signature(apiUrl + `/rest/v3/album/ad/set/info/sub${sub}`, {}, { sub }));

//PC定制版个人主页-获取定制相册分类列表
export const getCutsomAlbumList = (time, id, sub, page, cnt) => fetch(signature(apiUrl + `/rest/v3/album/ad/set/list/time${time}/id${id}/sub${sub}/page${page}/cnt${cnt}`, {}, { time, id, sub, page, cnt }));

//PC定制版个人主页-创建定制分类
export const createCutsomClass = (name, sub) => fetch(signature(apiUrl + `/rest/v3/album/ad/set/create`, {}, { name, sub }), { name, sub }, 'post')

//PC定制版个人主页-移动相册到分类
export const moveCutsomClass = (albumIdN, id) => fetch(signature(apiUrl + `/rest/v3/album/ad/set/move`, {}, { albumIdN, id }), { albumIdN, id }, 'post')

//PC定制版个人主页-搜索
export const searchCutsomAlbum = (sub, name) => fetch(signature(apiUrl + `/rest/v3/pc/albums/search`, {}, { sub, name }), { sub, name }, 'post')

// 图片排序页面 - 获取信息详情
export const getImgSortAlbum = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/sort/info/albumIdN${albumIdN}`, {}, { albumIdN }), {})

// 图片排序页面 - 获取图片
export const getImgSortImgList = ({ albumIdN, sepId, sortType, display, cnt, page }) => fetch(
    signature(apiUrl + `/rest/v3/photo/sort/photos/albumIdN${albumIdN}/sepId${sepId}/sortType${sortType}/display${display}/cnt${cnt}/page${page}`, {}, { albumIdN, sepId, sortType, display, cnt, page }), {})

// 图片排序页面 - 图片移动分类
export const setImgMove2OtherClass = ({ albumIdN, currentSepId, targetSepId, photoIds }) => fetch(
    signature(apiUrl + `/rest/v3/photo/sort/move/group`, {}, { albumIdN, currentSepId, targetSepId, photoIds }), { albumIdN, currentSepId, targetSepId, photoIds },
    'post')

// 图片排序页面 - 图片批量移动  /rest/v3/photo/sort/move
export const batchMoveImg = ({ albumIdN, sortType, photoIds, prefixPhotoId, suffixPhotoId, sepId, display }) => fetch(
    signature(apiUrl + `/rest/v3/photo/sort/move`, {}, { albumIdN, sortType, photoIds, prefixPhotoId, suffixPhotoId, sepId, display }), { albumIdN, sortType, photoIds, prefixPhotoId, suffixPhotoId, sepId, display },
    'post')

// 图片排序-获取后台当前是否已完成操作
export const getImgMoveStatus = ({ albumIdN, sepId }) => fetch(
    signature(apiUrl + `/rest/v3/pc/sort/state/detect/albumIdN${albumIdN}/sepId${sepId}`, {}, { albumIdN, sepId }))

//PC签约摄影师页面-获取作品集
// export const getWorkList = (type) => fetch(signature(apiUrl + `/rest/v3/app/pg/work/list`, {}, {type}), {type})
    // 用户登录接口
// export const userLogin = (name, pwd) => fetch(signature(apiUrl + `/rest/v3/login`, {}, { name, pwd }), { name, pwd }, 'post')
    //重置密码
// export const resetPassword = (vcode, phone, newPwd) => fetch(signature(apiUrl + `/rest/v3/user/modipass/last`, {}, { vcode, phone, newPwd }), { vcode, phone, newPwd }, 'post')

//PC本地上传获取新视频列表
// export const getLocalUploadVideoList = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/pc/videos/albumIdN${albumIdN}`, {}, { albumIdN }), {})

//PC本地上传 修改视频封面标题
// export const editVideoLocalUpload = (albumIdN, idN, type, coverUpload, title, url = "", width = "", height = "") => fetch(signature(apiUrl + `/rest/v3/pc/videos/modify`, {}, { albumIdN, idN, type, coverUpload, title, url, width, height }), { albumIdN, idN, type, coverUpload, title, url, width, height }, 'post')

//PC本地上传 视频排序
// export const videoSortLocalUpload = (albumIdN, ids) => fetch(signature(apiUrl + `/rest/v3/pc/video/sort`, {}, { albumIdN, ids }), { albumIdN, ids }, 'post')

//PC本地上传 上传新视频
// export const addVideoLocalUpload = (albumIdN, type, url, coverUpload, title, width, height, uuid, u) => {
//     let encodeTitle = encodeURIComponent(title)
//     let encodeUrl = encodeURIComponent(url)
//     return fetch(signature(apiUrl + `/rest/v3/pc/videos/upload/change`, {}, { albumIdN, type, coverUpload, title, width, height, uuid, u }), { albumIdN, type, url: encodeUrl, coverUpload, title: encodeTitle, width, height, uuid, u }, 'post')
// }

//PC本地上传 上传视频获取视频费用详情
// export const payVideoLocalUpload = (albumIdN, uploadOrigin, type, size) => fetch(signature(apiUrl + `/rest/v3/video/new/c`, {}, { albumIdN, uploadOrigin, type, size }), { albumIdN, uploadOrigin, type, size }, 'post')

//PC 视频上传状态检测
// export const checkoutVideoLocalUpload = (albumIdN, ids) => fetch(signature(apiUrl + `/rest/v3/pc/videos/state/check`, {}, { albumIdN, ids }), { albumIdN, ids }, 'post');

//PC 视频删除
// export const deleteVideoLocalUpload = (albumIdN, videoId) => fetch(signature(apiUrl + `/rest/v3/video/delete`, {}, { albumIdN, videoId }), { albumIdN, videoId }, 'post');

//PC 视频显示状态修改

export const changeVideoStatus = (albumIdN, videoId, display, type) => fetch(signature(apiUrl + `/rest/v3/video/display`, {}, { albumIdN, videoId, display, type }), { albumIdN, videoId, display, type }, 'post');



// 实人认证 用户详情返回 https://tower.im/teams/146563/documents/24672/?fullscreen=false
export const getRealNameInfo = () => fetch(signature(apiUrl + `/rest/v3/user/face/info`, {}, {}), {})


// 获取ai 修图介绍页面的
export const aiDetailGetCase = (type) => fetch(signature(apiUrl + `/rest/v3/ai/retouch/example/demo`, {}, {type}), {type});
// ai修图获取上传权限
export const getAiDeatilUploadAuth = (type, isAdjustColor, isAutorotation, isBeauty, dermabrasion, whiteness, isRemoveFreckle, isColorCorrecte, faceSlimIntensity, noseSlimIntensity, eyeBigIntensity, isGlobalBeauty, isFaceBeauty) => fetch(signature(apiUrl + `/rest/v3/airetouch/visitor/upload/auth`, {}, {type}), {type,  isAdjustColor, isAutorotation, isBeauty, dermabrasion, whiteness, isRemoveFreckle, isColorCorrecte, faceSlimIntensity, noseSlimIntensity, eyeBigIntensity, isGlobalBeauty, isFaceBeauty});
// 获取ai 修图介绍页面的
export const getAiDetailUploadLoading = (uuid,type) => fetch(signature(apiUrl + `/rest/v3/airetouch/visitor/check`, {}, {uuid,type}), {uuid,type}, 'post');


// 视频直播获取提问接口
export const getIssueList = (albumIdN, loadFlag, sortFlag, pag, cnt) => fetch(signature(apiUrl + `/rest/v3/live/load/question`, {}, {albumIdN, loadFlag, sortFlag, pag, cnt}), {albumIdN, loadFlag, sortFlag, pag, cnt}, 'post');
// 视频直播 显示/隐藏提问接口
export const displayIssue = (albumIdN, questionId, displayFlag) => fetch(signature(apiUrl + `/rest/v3/live/display/question`, {}, {albumIdN, questionId, displayFlag}), {albumIdN, questionId, displayFlag},  'post');

// 视频 直播回答接口
export const answerIssue = (albumIdN, questionId, answer, answerEn) => fetch(signature(apiUrl + `/rest/v3/live/answer/question`, {}, {albumIdN, questionId, answer, answerEn}), {albumIdN, questionId, answer, answerEn}, 'post');

// 视频 翻译问题
export const setQuestionEn = (albumIdN, questionId, questionEn) => fetch(signature(apiUrl + `/rest/v3/live/translate/question`, {}, {albumIdN, questionId, questionEn}), {albumIdN, questionId, questionEn}, 'post');



// 获取微信分享配置
export const wechatConfigApi = url =>fetch(signature(apiUrl + `/rest/v3/wechat/share`, {}, {url}), {url: encodeURIComponent(url)}, 'post');

// 获取购买商品权限
export const getShopAuth = () => fetch(signature(apiUrl + `/rest/v3/user/role/type/acquire`, {}, {}), {}, 'post');



// 获取用户会员过期信息
export const getMemberExpiration = () => fetch(signature(apiUrl + `/rest/v3/user/vip/state`, {}, {}));

// 自定义的相册风格设置保存
// export const submitCustomStyle = (title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, albumIdN) => fetch(signature(apiUrl + `/rest/v3/user/album/style/setting`, {}, {title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, albumIdN}), {title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, albumIdN}, 'post');

// 自定义风格获取次数
export const getAlbumStyleTime = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/user/get/album/style/times/change/albumIdN${albumIdN}`, {}, {albumIdN}));

// 自定义的相册风格广告关闭
export const closeCustomStyleAd = (type, albumIdN) => fetch(signature(apiUrl + `/rest/v3/app/album/closeAd`, {}, {type, albumIdN}),  {type, albumIdN}, 'post');

// 自定义的相册风格设置编辑
export const editCustomStyle = (id, title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, albumIdN) => fetch(signature(apiUrl + `/rest/v3/user/album/style/edit`, {}, {id, title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, albumIdN}), {id, title, themeColor, themeVersion, topUuid, midUuid, endUuid, previewUuid, albumIdN}, 'post');

// pc 用户签订协议
// export const signAgreement = ( type ) => fetch(signature(apiUrl + `/rest/v3/protocol/insert`, {}, {type}),  {type}, 'post');


// pc 查询用户是否签订协议
// export const selectSignAgreement = ( type ) => fetch(signature(apiUrl + `/rest/v3/protocol/query/type${type}`, {}, {type}));








//获取相册集
// export const getAlbumCollection = (albumSetId) => fetch(signature(apiUrl + '/rest/v3/set/get/albumSetId' + albumSetId, {}, { albumSetId }));

//获取作品墙图片
// export const worksWall = (pag = 1, cnt = 30, key) => fetch(signature(apiUrl + '/rest/v1/work/tpcontent', { pag, cnt, key }));

//获取作品集图片
// export const works = (id) => fetch(signature(apiUrl + '/rest/work', { show: id }, { workId: id }));

//获取摄影师信息
// export const photographer = (id) => fetch(signature(apiUrl + '/rest', { pg: id }, { id: id }));

//获取案例列表数据
// export const cases = (cnt, pag) => fetch(signature(apiUrl + '/rest/act/list', { cnt, pag, w: docWidth }));

//pc 视频案例页面

export const getVideoCasesInfo = (type, videoId, cnt, sort, videoType) => fetch(signature(apiUrl + `/rest/v3/index/video/case/type${type}/videoId${videoId}/cnt${cnt}/sort${sort}/videoType${videoType}`, {}, { type, videoId, cnt, sort, videoType }));

//统计
export const statistics = (param) => fetch(signature(apiUrl + '/rest/v3/avs', {}, param), param, 'post');

//创建相册
export const createAlbum = (title, albumAddr, adate) => fetch(signature(apiUrl + '/rest/v3/m/album/create/sim', {}, { title, albumAddr, adate }), { title, albumAddr, adate }, 'post');





// 设置相册菜单布局形式
export const settingALbumNavLayout = (albumIdN, menuShowStyle) => fetch(signature(apiUrl + '/rest/v3/album/pc/menu/style/setting', {}, { albumIdN, menuShowStyle }), { albumIdN, menuShowStyle }, 'post');

// pc相册开关设置
// export const settingALbumSwitch = (albumIdN, pcSwitchJson) => fetch(signature(apiUrl + '/rest/v3/album/pc/switch/type', {}, { albumIdN, pcSwitchJson }), { albumIdN, pcSwitchJson }, 'post');

/********************************************************
 *                                                      *
 *　　　　　　               上传                       　 *
 *                                                      *
 *********************************************************/
//pc　获取上传权限
//type 1获取背景图片上传权限
//     2获取头像图片上传权限
// 简历头像上传
// export const getUploadHeadAuthority = (type) => fetch(signature(apiUrl + `/rest/v3/auth/type${type}` , {} , {type}));
//暂时的上传权限
export const getUploadAuthority = (type, albumIdN = 0) => fetch(signature(apiUrl + `/rest/v3/auth/type${type}/albumIdN${albumIdN}`, {}, { type, albumIdN }));

//签约摄影师上传图片　获取图片id (摄影师、修图师)
// export const getUploadId = (names,type, categoryId) => {
//     let encodeNameList = JSON.parse(names);
//     encodeNameList = encodeNameList.map(name => {
//         return encodeURIComponent(name);
//     });
//     encodeNameList = JSON.stringify(encodeNameList);
//     return fetch(signature(apiUrl + '/rest/v3/sign/photo/getid', {}, { names, type , categoryId}), { names: encodeNameList, type, categoryId }, 'post')
// };




/********************************************************
 *                                                      *
 *　　　                     拼图                     　  *
 *                                                      *
 ********************************************************/


//拼图
// export const puzzle = (ids) => fetch(signature(apiUrl + '/rest/v3/act/img/combine', {}, { ids }), { ids }, 'post');






/********************************************************
 *                                                      *
 *　　　                  json数据                    　  *
 *                                                      *
 ********************************************************/


export const getFetch = (url) => fetch(url);







/********************************************************
 *                                                      *
 *　　　                  活动页面                    　  *
 *                                                      *
 ********************************************************/

export const getSettingUserInfo = (albumIdN) => fetch(signature(apiUrl + `/rest/v3/album/owner/albumIdN${albumIdN}`, {}, { albumIdN }));
// export const settingAiRetouch = (albumIdN, type, time, isAdjustColor, isAutorotation, isBeauty, dermabrasion, whiteness, isRemoveFreckle, isColorCorrecte, faceSlimIntensity, noseSlimIntensity, eyeBigIntensity, isGlobalBeauty, isFaceBeauty ) => fetch(signature(apiUrl + '/rest/v3/album/ai/retouch/setting', {}, { albumIdN, type, time }), { albumIdN, type, time, isAdjustColor, isAutorotation, isBeauty, dermabrasion, whiteness, isRemoveFreckle, isColorCorrecte, faceSlimIntensity, noseSlimIntensity, eyeBigIntensity, isGlobalBeauty, isFaceBeauty }, 'post');
// export const previewModification = (fileName, isAdjustColor, isAutorotation, isBeauty, dermabrasion, whiteness, isRemoveFreckle, isColorCorrecte, faceSlimIntensity, noseSlimIntensity, eyeBigIntensity, isGlobalBeauty, isFaceBeauty) => fetch(signature(apiUrl + '/rest/v3/airetouch/visitor/preview', {}, {fileName, isAdjustColor, isAutorotation, isBeauty, dermabrasion, whiteness, isRemoveFreckle, isColorCorrecte, faceSlimIntensity, noseSlimIntensity, eyeBigIntensity, isGlobalBeauty, isFaceBeauty }), {fileName, isAdjustColor, isAutorotation, isBeauty, dermabrasion, whiteness, isRemoveFreckle, isColorCorrecte, faceSlimIntensity, noseSlimIntensity, eyeBigIntensity, isGlobalBeauty, isFaceBeauty }, 'post');
// export const getDefaultParams = () => fetch(signature(apiUrl + `/rest/v3/airetouch/default/params`, {}, {}));


// export const deleteAiRetouch = (albumIdN, id) => fetch(signature(apiUrl + '/rest/v3/album/ai/retouch/setting/del', {}, { albumIdN, id }), { albumIdN, id }, 'post');

/********************************************************
 *                                                      *
 *　　　                  SEO相关配置文件                  *
 *                                                      *
 ********************************************************/


export const getSeoBeeConfig = () => fetch(`https://spu.${domain}.com/default/json/bee.json`, {});

/********************************************************
 *                                                      *
 *　　　                  问答                *
 *                                                      *
 ********************************************************/
// 置顶
export const setTopQuestion = (albumIdN, questionId, topFlag) => fetch(signature(apiUrl + '/rest/v3/live/top/question', {}, { albumIdN, questionId, topFlag }), { albumIdN, questionId, topFlag }, 'post');

/********************************************************
 *                                                      *
 *　　　                  相册解冻                *
 *                                                      *
 ********************************************************/
// 解冻相册
export const checkAlbumStatus = (albumIdN) => fetch(signature(apiUrl + '/rest/v3/check/archive/status', {}, { albumIdN}), { albumIdN }, 'post');

// 冷冻相册 测试用
export const freezePhoto = (pIds) => fetch(signature(cloudStorageAPI + '/rest/v4/photoFreeze/restorePhoto', {}, { pIds}), { pIds }, 'post');

// 查询解冻状态
export const checkFreezeStatus = (pIds) => fetch(signature(cloudStorageAPI + '/rest/v4/photoFreeze/restorePhotoComfirm', {}, { pIds}), { pIds }, 'post');

// 付费下载 查询协议
// export const checkAgreement = (type, albumIdN) => fetch(signature(apiUrl + '/rest/v3/photo/protocol/query', {}, { type, albumIdN }), { type, albumIdN }, 'post');

// 付费下载 签订协议
// export const agreeAgreement = (type, albumIdN) => fetch(signature(apiUrl + `/rest/v3/photo/protocol/insert`, {}, { type, albumIdN }), { type, albumIdN }, 'post');

// 付费下载 获取相册收入
// export const getAlbumIncome = (albumIdN) => fetch(signature(apiUrl + '/rest/v3/album/income/detail', {}, { albumIdN }), { albumIdN }, 'post');


// 获取专题模版
export const specialTemplateList = (labelId, page, cnt) => fetch(signature(micrositeServerUrl + `/rest/v3/speical/topic/findSpecialTopicFormatNew`, {}, {labelId, page, cnt}) + `?labelId=${labelId}&page=${page}&cnt=${cnt}`);
// 获取模版类型枚举
export const specialTemplateTypeList = () => fetch(signature(albumRevisionUrl + `/rest/v3/speical/topic/findSpecialTopicLabels`, {}, {}), {}, 'post');

// 新验证码登陆
export const newVerificationLogin = (name, sms) => fetch(signature(albumRevisionUrl + `/rest/v3/user/base/loginBySms/`, {}, { name, sms }), { name, sms }, 'post');

// 新重置密码
export const newResetPassword = (phone, sms , pwd) => fetch(signature(albumRevisionUrl + `/rest/v3/user/base/findPwd`, {}, { phone, sms , pwd}), { phone, sms , pwd}, 'post');

// 新 个人资料页面 - 安全中心 - 修改密码
export const newEditPwd = ({ oldPwd, newPwd }) => fetch(signature(albumRevisionUrl + `/rest/v3/user/base/modifyPwdV3`, {}, { oldPwd, newPwd }), { oldPwd: encodeURIComponent(oldPwd), newPwd: encodeURIComponent(newPwd) }, 'post')

// 新用户登录接口
export const newUserLogin = (name, pwd) => fetch(signature(albumRevisionUrl + `/rest/v3/user/base/loginNew`, {}, { name, pwd }), { name, pwd }, 'post')

// 新删除相册
export const newDeleteStorageAlbum = (ids) => fetch(signature(albumRevisionUrl + `/rest/v3/album/base/batchDeleteAlbum`, {}, { ids }), { ids }, 'post');
